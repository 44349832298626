import React from 'react'
import Search from '../components/searchContainer'

const IndexPage = () => (
  <div>
    <h1 style={{ marginTop: `3em`, textAlign: `center` }}>Search data</h1>
    <div>
      <Search />
    </div>
  </div>
)

export default IndexPage
